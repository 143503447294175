import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Crypto = () => {
  const [input, setinput] = useState("btc");
  const [coin, setcoin] = useState({});
  const [show, setshow] = useState(false);

  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  let query = urlParams.get("q");

  const handlechange = (e) => {
    setinput(e.target.value);
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    navigate(`?q=${input}`);
    setshow(true);

    axios
      .get(
        `https://api.kucoin.com/api/v1/market/stats?symbol=${query.toUpperCase()}-USDT`
      )
      .then((res) => {
        setcoin(res.data.data);
      });
  };

  useEffect(()=>{
    document.title="Cryptocurrency price checker"
  })

  return (
    <>
      <h2 className="heading">Crypto price lookup</h2>

      <div className="coinsearcher">
        <form onSubmit={handlesubmit}>
          <input
            onChange={handlechange}
            placeholder="Search any coin.."
            list="coins"
            type="text"
          />
          <datalist id="coins">
            <option value="BTC">Bitcoin</option>
            <option value="ETH">Ethereum</option>
            <option value="USDT">Tether</option>
            <option value="USDC">USD Coin</option>
            <option value="BNB">Binance Coin</option>
            <option value="BUSD">Binance USD</option>
            <option value="XRP">XRP</option>
            <option value="ADA">Cardano</option>
            <option value="SOL">Solana</option>
            <option value="DOGE">Dogecoin</option>
            <option value="DOT">Polkadot</option>
            <option value="SHIB">Shiba Inu</option>
            <option value="STETH">Liquid Staked Ether</option>
            <option value="HEX">HEX</option>
            <option value="DAI">Dai</option>
            <option value="AVAX">Avalanche</option>
            <option value="MATIC">Polygon</option>
            <option value="TRX">TRON</option>
            <option value="WBTC">Wrapped Bitcoin</option>
            <option value="LEO">UNUS SED LEO</option>
            <option value="FTT">FTX Token</option>
            <option value="OKB">OKEx Utility Token</option>
            <option value="ETC">Ethereum Classic</option>
            <option value="LTC">Litecoin</option>
            <option value="NEAR">Near Protocol</option>
            <option value="LINK">ChainLink</option>
            <option value="UNI">Uniswap Protocol Token</option>
            <option value="CRO">Cronos</option>
            <option value="ATOM">Cosmos</option>
            <option value="XLM">Stellar</option>
            <option value="XMR">Monero</option>
            <option value="FLOW">Flow</option>
            <option value="BCH">Bitcoin Cash</option>
            <option value="ALGO">Algorand</option>
            <option value="OP">Optimism</option>
            <option value="VET">VeChain Thor</option>
            <option value="FIL">Filecoin</option>
            <option value="APE">ApeCoin</option>
            <option value="ICP">Dfinity</option>
            <option value="MANA">Decentraland</option>
            <option value="SAND">The Sandbox</option>
            <option value="HBAR">Hedera Hashgraph</option>
            <option value="XTZ">Tezos</option>
            <option value="FRAX">Frax</option>
            <option value="EOS">EOS</option>
            <option value="QNT">Quant</option>
            <option value="CRI">Crypto International</option>
            <option value="TUSD">TrueUSD</option>
            <option value="CHZ">Chiliz</option>
            <option value="THETA">Theta Token</option>
            <option value="LDO">Lido DAO</option>
            <option value="EGLD">Elrond</option>
            <option value="AAVE">Aave</option>
            <option value="CUSDC">Compound USD Coin</option>
            <option value="CEL">Celsius</option>
            <option value="AXS">Axie Infinity</option>
            <option value="1INCH">1inch</option>
            <option value="BSV">Bitcoin SV</option>
            <option value="KCS">KuCoin Token</option>
            <option value="CETH">Compound Ether</option>
            <option value="HNT">Helium</option>
            <option value="OMI">ECOMI</option>
            <option value="USDP">Pax Dollar</option>
            <option value="BTT">BitTorrent V2</option>
            <option value="ZEC">Zcash</option>
            <option value="CDAI">Compound Dai</option>
            <option value="IOT">IOTA (MIOTA)</option>
            <option value="GRT">The Graph</option>
            <option value="FTM">Fantom</option>
            <option value="XEC">eCash</option>
            <option value="MKR">Maker</option>
            <option value="ELG">Escoin Token</option>
            <option value="KLAY">Klaytn</option>
            <option value="USDN">Neutrino</option>
            <option value="NEO">NEO</option>
            <option value="HT">Huobi Token</option>
            <option value="HBTC">Huobi BTC (ERC-20)</option>
            <option value="SNX">Synthetix Network Token</option>
            <option value="BTSE">BTSE Token</option>
            <option value="RUNE">THORChain</option>
            <option value="GT">Gatechain Token</option>
            <option value="AR">Arweave</option>
            <option value="CAKE">PancakeSwap</option>
            <option value="PAXG">PAX Gold</option>
            <option value="LUNC">Terra Luna Classic</option>
            <option value="CRV">Curve DAO Token</option>
            <option value="ZIL">Zilliqa</option>
            <option value="BAT">Basic Attention Token</option>
            <option value="AMP">AMP Token</option>
            <option value="ENJ">Enjin Coin</option>
            <option value="STX">Stacks</option>
            <option value="DASH">Dash</option>
            <option value="WAVES">Waves</option>
            <option value="TIME">TIME (Wonderland)</option>
            <option value="NEXO">Nexo</option>
            <option value="CUSDT">Compound USDT</option>
            <option value="LRC">Loopring</option>
            <option value="GNO">Gnosis</option>
            <option value="KAVA">Kava</option>
            <option value="TWT">Trust Wallet</option>
            <option value="FXS">Frax Share (ERC-20)</option>
            <option value="DFI">DeFiChain</option>
            <option value="MINA">Mina Protocol</option>
            <option value="BTG">Bitcoin Gold</option>
            <option value="KSM">Kusama</option>
            <option value="XAUT">Tether Gold</option>
            <option value="XEM">NEM</option>
            <option value="TKX">Tokenize Xchange</option>
            <option value="GALA">Gala</option>
            <option value="DCR">Decred</option>
            <option value="HOT">Holo</option>
            <option value="RPL">Rocket Pool</option>
            <option value="CELO">Celo</option>
            <option value="XDC">XinFin Digital Contract</option>
            <option value="ROSE">Oasis Network</option>
            <option value="CVX">Convex Finance</option>
            <option value="QTUM">Qtum</option>
            <option value="COMP">Compound</option>
            <option value="GMT">STEPN Governance Token</option>
            <option value="RVN">RavenCoin</option>
            <option value="ENS">Ethereum Name Service</option>
            <option value="ANKR">Ankr</option>
            <option value="GUSD">Gemini Dollar</option>
            <option value="SRM">Serum</option>
            <option value="IOST">IOStoken</option>
            <option value="KDA">Kadena</option>
            <option value="IOTX">IoTeX</option>
            <option value="SUSHI">Sushi</option>
            <option value="YFI">yearn.finance</option>
            <option value="ONE">Harmony</option>
            <option value="OMG">OMG Network</option>
            <option value="ZRX">0x</option>
            <option value="JST">JUST</option>
            <option value="LPT">livepeer</option>
            <option value="AUDIO">Audius</option>
            <option value="USDP">USDP Stablecoin</option>
            <option value="LN">LINK</option>
            <option value="LUNA">Terra</option>
            <option value="ICX">ICON</option>
            <option value="USTC">TerraClassicUSD</option>
            <option value="ONT">Ontology</option>
            <option value="BTT">BitTorrent V1 (OLD)</option>
            <option value="WAXP">WAX Protocol Token</option>
            <option value="BAL">Balancer</option>
            <option value="FLUX">Flux</option>
            <option value="NU">NuCypher</option>
            <option value="SC">Siacoin</option>
            <option value="HIVE">Hive</option>
            <option value="DAG">Constellation</option>
            <option value="ZEN">Horizen</option>
            <option value="SPHERE">Cronosphere</option>
            <option value="SCRT">Secret</option>
            <option value="UMA">UMA</option>
            <option value="WOO">Wootrade Network</option>
            <option value="SXP">Swipe (ERC-20)</option>
            <option value="DYDX">dYdX</option>
            <option value="XCH">Chia</option>
            <option value="SKL">Skale</option>
            <option value="OGN">Origin Protocol</option>
            <option value="XCM">CoinMetro Token</option>
            <option value="ERG">Ergo</option>
            <option value="GNT">Golem</option>
            <option value="POLY">Polymath</option>
            <option value="MXC">Machine Xchange Coin</option>
            <option value="BTCST">BTC Standard Hashrate Token</option>
            <option value="CSPR">Casper Network</option>
            <option value="CHSB">SwissBorg</option>
            <option value="PLA">PlayDapp</option>
            <option value="DGB">DigiByte</option>
            <option value="RNDR">Render Token</option>
            <option value="LSK">Lisk</option>
            <option value="CEEK">CEEK</option>
            <option value="SFM">SafeMoon V2</option>
            <option value="FX">Function X</option>
            <option value="CKB">Nervos Network</option>
            <option value="EWT">Energy Web Token</option>
            <option value="MASK">Mask Network</option>
            <option value="REN">REN</option>
            <option value="NEST">Nest</option>
            <option value="MBCASH">MBCash</option>
            <option value="WAX">WAX Token (ERC-20)</option>
            <option value="WIN">WINk</option>
            <option value="EURS">EURS</option>
            <option value="ALTA">Alta Finance</option>
            <option value="CVC">Civic</option>
            <option value="XNO">Nano</option>
            <option value="ORBS">Orbs</option>
            <option value="SNT">Status</option>
            <option value="MX">MX Token</option>
            <option value="MED">MediBloc</option>
            <option value="API3">API3</option>
            <option value="NDAU">Ndau</option>
            <option value="MVL">Mass Vehicle Ledger</option>
            <option value="CELR">Celer Network (ERC-20)</option>
            <option value="TITAN">TitanSwap</option>
            <option value="PEOPLE">ConstitutionDAO</option>
            <option value="PLTC">PlatonCoin</option>
            <option value="ARDR">Ardor</option>
            <option value="CFX">Conflux Token</option>
            <option value="EXRD">e-Radix</option>
            <option value="RLY">Rally</option>
            <option value="NMR">Numeraire</option>
            <option value="COTI">COTI Token</option>
            <option value="INJ">Injective Protocol</option>
            <option value="SUSD">Synth sUSD</option>
            <option value="TRIBE">Tribe</option>
            <option value="BNTX">Bintex Futures</option>
            <option value="BNT">Bancor</option>
            <option value="PROM">Prometeus</option>
            <option value="DKA">dKargo</option>
            <option value="TEL">Telcoin</option>
            <option value="SYS">Syscoin</option>
            <option value="SBTC">sBTC</option>
            <option value="HDRN">Hedron</option>
            <option value="VLX">Velas</option>
            <option value="POWR">Power Ledger</option>
            <option value="SURE">inSure DeFi</option>
            <option value="REV">Revain</option>
            <option value="DENT">Dent</option>
            <option value="TRB">Tellor Tributes</option>
            <option value="CHR">Chromia</option>
            <option value="PLEX">PLEX</option>
            <option value="STEEM">Steem</option>
            <option value="XYO">XYO</option>
            <option value="KEEP">Keep Network</option>
            <option value="ADS">Adshares (ERC-20)</option>
            <option value="RAD">Radicle</option>
            <option value="WRX">WazirX</option>
            <option value="CTSI">Cartesi</option>
            <option value="VTHO">VeThor Token</option>
            <option value="XWC">WhiteCoin</option>
            <option value="RAY">Raydium</option>
            <option value="REQ">Request Network</option>
            <option value="AETH">ankrETH</option>
            <option value="STRAX">Stratis</option>
            <option value="EURT">Tether EURt</option>
            <option value="ARRR">Pirate Chain</option>
            <option value="RLC">iExec RLC</option>
            <option value="FUN">FunFair</option>
            <option value="BDX">Beldex</option>
            <option value="STORJ">Storj</option>
            <option value="UOS">Ultra</option>
            <option value="UQC">Uquid Coin</option>
            <option value="LYXE">LUKSO Token</option>
            <option value="FLOKI">Floki Inu (ERC-20) V2</option>
            <option value="XVS">Venus</option>
            <option value="STMX">StormX</option>
            <option value="ILV">Illuvium</option>
            <option value="TRAC">OriginTrail</option>
            <option value="HXRO">HXRO</option>
            <option value="MTL">Metal</option>
            <option value="VGX">Voyager Token (Version 1.0 OLD)</option>
            <option value="CTC">Creditcoin</option>
            <option value="STPT">STP Network</option>
            <option value="ARK">Ark</option>
            <option value="STORM">Storm</option>
            <option value="ALI">AiLink Token</option>
            <option value="REEF">Reef Finance</option>
            <option value="OCEAN">Ocean Protocol</option>
            <option value="MDX">MDX Token</option>
            <option value="KLV">Klever</option>
            <option value="ELF">aelf</option>
            <option value="QKC">QuarkChain</option>
            <option value="AMPL">Ampleforth</option>
            <option value="DODO">DODO</option>
            <option value="ACH">Alchemy Pay</option>
            <option value="DAWN">Dawn Protocol</option>
            <option value="MOVR">Moonriver</option>
            <option value="OXT">Orchid Protocol</option>
            <option value="META">Metadium</option>
            <option value="VERI">Veritaseum</option>
            <option value="BOND">BarnBridge</option>
            <option value="DOOR">DOOR</option>
            <option value="ANT">Aragon Network Token</option>
            <option value="SUN">Sun Token</option>
            <option value="GHST">Aavegotchi</option>
            <option value="WOZX">EFFORCE</option>
            <option value="REPV2">Augur v2</option>
            <option value="RIF">RIF Token</option>
            <option value="RGT">Rari Governance Token</option>
            <option value="NKN">NKN</option>
            <option value="FET">Fetch</option>
            <option value="T">Threshold Network Token</option>
            <option value="UTK">Utrust</option>
            <option value="FORTH">Ampleforth Governance Token</option>
            <option value="CBAT">Compound Basic Attention Token</option>
            <option value="XSGD">XSGD</option>
            <option value="SUPER">SuperFarm</option>
            <option value="CORE">cVault.finance</option>
            <option value="ALPHA">Alpha Finance</option>
            <option value="BAND">Band Protocol</option>
            <option value="GXC">GXChain Core Asset</option>
            <option value="VRA">VERAsity</option>
            <option value="IQ">Everipedia</option>
            <option value="LAND">Meta Utopia</option>
            <option value="TRU">TrueFi</option>
            <option value="CDT">Blox</option>
            <option value="UNFI">Unifi Protocol DAO</option>
            <option value="TLOS">Telos</option>
            <option value="TT">ThunderCore</option>
            <option value="PERP">Perpetual Protocol</option>
            <option value="LEASH">Leash</option>
            <option value="DUSK">Dusk Network (ERC-20)</option>
            <option value="AERGO">Aergo</option>
            <option value="PYR">Vulcan Forged (Polygon)</option>
            <option value="POLS">Polkastarter (ERC-20)</option>
            <option value="LAND">Meta Utopia</option>
            <option value="TON">Tokamak Network</option>
            <option value="KP3R">Keep3rV1</option>
            <option value="BAKE">BakeryToken</option>
            <option value="MFT">Mainframe</option>
            <option value="DIVI">Divi</option>
            <option value="OUSD">Origin Dollar</option>
            <option value="ETN">Electroneum</option>
            <option value="ALEPH">Aleph</option>
            <option value="SANTOS">Santos FC Fan Token</option>
            <option value="DEP">DEAPCOIN</option>
            <option value="HUNT">HUNT Token</option>
            <option value="CUSD">Celo Dollar</option>
            <option value="WNXM">Wrapped NXM</option>
            <option value="OVO">Ovato</option>
            <option value="XPR">Proton (ERC-20)</option>
            <option value="KNCL">KNC Legacy</option>
            <option value="IDEX">IDEX</option>
            <option value="DERO">Dero</option>
            <option value="SERO">Super Zero</option>
            <option value="YFII">YFII.money</option>
            <option value="TOMO">TomoChain</option>
            <option value="MTRG">Meter</option>
            <option value="HOO">Hoo Token</option>
            <option value="AGEUR">agEUR</option>
            <option value="PHA">PHA</option>
            <option value="USDK">USDK</option>
            <option value="APM">apM Coin</option>
            <option value="AVINOC">AVINOC</option>
            <option value="ORN">Orion Protocol</option>
            <option value="HYN">Hyperion</option>
            <option value="PRO">Propy</option>
            <option value="ALCX">Alchemix</option>
            <option value="CRE">Carry</option>
            <option value="HYDRA">Hydra</option>
            <option value="BZRX">bZx Protocol Token</option>
            <option value="LOKA">League of Kingdoms Arena</option>
            <option value="ELA">Elastos</option>
            <option value="TRYB">BiLira</option>
            <option value="ERN">Ethernity Chain</option>
            <option value="RAMP">Ramp DeFi (ERC-20) (OLD)</option>
            <option value="POND">Marlin</option>
            <option value="CZRX">Compound 0x</option>
            <option value="COCOS">COCOS BCX</option>
            <option value="WAN">Wanchain</option>
            <option value="BTCV">Bitcoin Vault</option>
            <option value="AVA">Travala.com</option>
            <option value="BEL">Bella Protocol</option>
            <option value="LOOM">Loom Network</option>
            <option value="NSBT">Neutrino System Base Token</option>
            <option value="MUSD">mStable USD</option>
            <option value="COVAL">Circuits of Value</option>
            <option value="PYR">Vulcan Forged</option>
            <option value="BADGER">Badger</option>
            <option value="BMX">BitMart Token</option>
            <option value="GAS">Gas</option>
            <option value="MOC">Mossland</option>
            <option value="LCX">LCX</option>
            <option value="ONIT">ONBUFF</option>
            <option value="ALPACA">Alpaca Finance</option>
            <option value="MLN">Enzyme MLN Token</option>
            <option value="FCT">Firmachain</option>
            <option value="AQT">Alpha Quark Token</option>
            <option value="AE">Aeternity</option>
            <option value="LOOKS">LooksRare</option>
            <option value="NOIA">NOIA Network</option>
            <option value="ARPA">ARPA Chain (ERC-20)</option>
            <option value="DPI">DefiPulse Index</option>
            <option value="SAITO">Saito</option>
            <option value="LINA">Linear</option>
            <option value="LTO">LTO Network Token</option>
            <option value="DNT">district0x</option>
            <option value="SBD">Steem Backed Dollars</option>
            <option value="KMD">Komodo</option>
            <option value="RFR">Refereum</option>
            <option value="DIA">DIAdata</option>
            <option value="PRE">Presearch</option>
            <option value="HTR">Hathor</option>
            <option value="QC">Qcash</option>
            <option value="BIFI">Beefy.Finance</option>
            <option value="BCD">Bitcoin Diamond</option>
            <option value="LEND">Aave (Old)</option>
            <option value="HNS">Handshake</option>
            <option value="KOIN">Koinos</option>
            <option value="SETH">sETH</option>
            <option value="CTK">CertiK Token</option>
            <option value="LEOS">Leonicorn Swap</option>
            <option value="FARM">Harvest Finance</option>
            <option value="MINTME">MintMe.com Coin</option>
            <option value="PSG">Paris Saint-Germain Fan Token</option>
            <option value="SOS">OpenDAO</option>
            <option value="PIT">Pitbull</option>
            <option value="SOUL">Phantasma</option>
            <option value="WING">Wing Finance</option>
            <option value="USDN">Neutrino USD (ERC-20)</option>
            <option value="FLOKI">Floki Inu (BEP-20) V2</option>
            <option value="HOLY">Holyheld</option>
            <option value="BTS">BitShares</option>
            <option value="MAP">Marcopolo</option>
            <option value="RSV">Reserve</option>
            <option value="FIO">FIO Protocol</option>
            <option value="MIR">Mirror Protocol</option>
            <option value="GET">Guaranteed Entrance Token</option>
            <option value="ROOK">ROOK</option>
            <option value="UBT">Unibright</option>
            <option value="IRIS">IRIS Network</option>
            <option value="QASH">QASH</option>
            <option value="TPT">TokenPocket</option>
            <option value="CXO">CargoX</option>
            <option value="NCT">Nectar</option>
            <option value="FIDA">FIDA</option>
            <option value="KEY">SelfKey</option>
            <option value="BLZ">Bluzelle</option>
            <option value="RARI">Rarible</option>
            <option value="CTXC">Cortex</option>
            <option value="GRS">Groestlcoin</option>
            <option value="HVE2">Uhive</option>
            <option value="COS">Contentos</option>
            <option value="DG">Decentral Games V1 (OLD)</option>
            <option value="UNIC">UniCoin</option>
            <option value="FOX">FOX Token</option>
            <option value="OM">MANTRA</option>
            <option value="MIX">MixMarvel</option>
            <option value="OOKI">Ooki</option>
            <option value="BTR">Bitrue Coin</option>
            <option value="ICHI">ichi.farm</option>
            <option value="EMB">EmberCoin</option>
            <option value="MATH">MATH</option>
            <option value="UPP">Sentinel Protocol</option>
            <option value="GIO">Graviocoin</option>
            <option value="PLSD">PulseDogecoin</option>
            <option value="ADX">Ambire AdEx</option>
            <option value="VITE">Vite</option>
            <option value="KIN">Kin</option>
            <option value="HEZ">Hermez Network</option>
            <option value="AUTO">Auto</option>
            <option value="ALBT">AllianceBlock</option>
            <option value="XZC">Zcoin</option>
            <option value="VELO">Velo</option>
            <option value="DATA">Streamr</option>
            <option value="TOMOE">TomoChain ERC-20</option>
            <option value="PRQ">PARSIQ</option>
            <option value="DAD">DAD</option>
            <option value="FLM">Flamingo Finance</option>
            <option value="AION">Aion</option>
            <option value="NULS">Nuls</option>
            <option value="DDX">DerivaDAO</option>
            <option value="IDRT">Rupiah Token</option>
            <option value="OVR">OVR (ERC-20)</option>
            <option value="MWC">MimbleWimbleCoin</option>
            <option value="FRONT">Frontier</option>
            <option value="BAR">FC Barcelona Fan Token</option>
            <option value="DXD">DXdao</option>
            <option value="LOC">LockTrip</option>
            <option value="SOLVE">SOLVE</option>
            <option value="TNB">Time New Bank</option>
            <option value="PORTO">FC Porto</option>
            <option value="TVK">Terra Virtua Kolect</option>
            <option value="NRG">Energi</option>
            <option value="DUSD">DefiDollar</option>
            <option value="BTM">Bytom</option>
            <option value="FIS">StaFi</option>
            <option value="TSD">True Seigniorage Dollar</option>
            <option value="BEAM">BEAM</option>
            <option value="MRPH">Morpheus Network V1 (OLD)</option>
            <option value="ORCA">Orca</option>
            <option value="LTX">Lattice Token</option>
            <option value="VRSC">Verus Coin</option>
            <option value="DVI">Dvision Network</option>
            <option value="SIX">SIX Network</option>
            <option value="FUSE">Fuse Token (ERC-20)</option>
            <option value="WISE">Wise</option>
            <option value="PLU">Pluton</option>
            <option value="NWC">Newscrypto</option>
            <option value="GRID">Grid+</option>
            <option value="BOA">BOSAGORA</option>
            <option value="SWAP">TrustSwap Token</option>
            <option value="BTCP">Bitcoin Pro</option>
            <option value="DEXE">DeXe</option>
            <option value="AXEL">AXEL</option>
            <option value="AST">AirSwap</option>
            <option value="ETHBULL">3X Long Ethereum Token</option>
            <option value="FSN">Fusion</option>
            <option value="HARD">Kava Lend</option>
            <option value="KRL">Kryll</option>
            <option value="JUV">Juventus</option>
            <option value="LON">Tokenlon</option>
            <option value="WICC">WaykiChain</option>
            <option value="YCC">Yuan Chain</option>
            <option value="CSC">CasinoCoin</option>
            <option value="KAI">KardiaChain</option>
            <option value="MDT">Measurable Data Token</option>
            <option value="ABT">Arcblock</option>
            <option value="TORN">Tornado Cash</option>
            <option value="PNK">Kleros</option>
            <option value="YLD">YIELD App</option>
            <option value="BAN">Banano</option>
            <option value="CPC">CPChain</option>
            <option value="MCB">MCDEX Token (ERC-20)</option>
            <option value="MEME">Memetic / PepeCoin</option>
            <option value="DF">dForce (ERC-20)</option>
            <option value="CSC">CasinoCoin (XRPL)</option>
            <option value="RAI">Rai Reflex Index</option>
            <option value="SYLO">Sylo</option>
            <option value="INSTAR">Insights Network</option>
            <option value="HDP.Ф">HEdpAY</option>
            <option value="BANANA">ApeSwap</option>
            <option value="VXV">Vectorspace AI</option>
            <option value="DEXT">DEXTools</option>
            <option value="MBL">MovieBloc</option>
            <option value="GST">STEPN Game Token</option>
            <option value="NIM">Nimiq</option>
            <option value="MITH">Mithril</option>
            <option value="AKT">Akash Network</option>
            <option value="NFTX">NFTX</option>
            <option value="DGD">DigixDAO</option>
            <option value="QSP">Quantstamp</option>
            <option value="ATLAS">Star Atlas</option>
            <option value="MET">Metronome</option>
            <option value="WXT">Wirex</option>
            <option value="FWT">Freeway Token</option>
            <option value="OXEN">Oxen</option>
            <option value="GTO">Gifto</option>
            <option value="GAT">Global Awards Token</option>
            <option value="SEUR">sEUR</option>
            <option value="GMT">Mercury Protocol</option>
            <option value="DASHD">Dash Diamond</option>
            <option value="WHALE">WHALE</option>
            <option value="NBS">New BitShares</option>
            <option value="ZKS">ZKSwap</option>
            <option value="ARV">ARIVA</option>
            <option value="HIBS">Hiblocks</option>
            <option value="CRPT">Crypterium</option>
            <option value="RAC">RAC</option>
            <option value="PNT">pNetwork</option>
            <option value="APL">Apollo</option>
            <option value="CREAM">Cream Finance</option>
            <option value="VIDT">V-ID blockchain</option>
            <option value="GBYTE">Obyte (gigabytes)</option>
            <option value="INDEX">Index Cooperative</option>
            <option value="SCP">ScPrime</option>
            <option value="AVT">Aventus</option>
            <option value="UFT">UniLend Finance Token</option>
            <option value="LBC">LBRY Credits</option>
            <option value="SWFTC">SwftCoin (ERC-20)</option>
            <option value="BTU">BTU Protocol</option>
            <option value="SNTR">Silent Notary</option>
            <option value="VRO">VeraOne</option>
            <option value="CRU">Crust Network</option>
            <option value="JUP">Jupiter</option>
            <option value="CAP">Cap</option>
            <option value="NEBL">Neblio</option>
            <option value="PPC">Peercoin</option>
            <option value="DOLLARS">Dollars</option>
            <option value="SWTH">Switcheo Token</option>
            <option value="PIVX">PIVX</option>
            <option value="BOT">Bodhi Token</option>
            <option value="QRL">Quantum Resistant Ledger</option>
            <option value="FOR">ForTube</option>
            <option value="EL">ELYSIA</option>
            <option value="VTC">Vertcoin</option>
            <option value="NGC">NAGA</option>
            <option value="VALOR">Valor Token</option>
            <option value="DEGO">Dego Finance (ERC-20)</option>
            <option value="UNCX">UniCrypt</option>
            <option value="REVV">REVV</option>
            <option value="EDG">Edgeware</option>
            <option value="FOAM">FOAM Token</option>
            <option value="POLIS">Star Atlas DAO</option>
            <option value="CHAIN">Chain Games</option>
            <option value="CVP">PowerPool Concentrated Voting Power</option>
            <option value="RBC">Rubic</option>
            <option value="SUKU">SUKU</option>
            <option value="XCP">Counterparty</option>
            <option value="BORING">BoringDAO</option>
            <option value="RDD">ReddCoin</option>
            <option value="EPIC">Epic Cash</option>
            <option value="PNY">Peony Coin</option>
            <option value="HAI">HackenAI</option>
            <option value="CLO">Callisto</option>
            <option value="MYST">Mysterium</option>
            <option value="CUBE">Somnium Space CUBEs</option>
            <option value="GAL">Galatasaray Fan Token</option>
            <option value="VID">VideoCoin</option>
            <option value="MUSE">Muse</option>
            <option value="BEPRO">BetProtocol</option>
            <option value="BTNT">BitNautic (BEP-20)</option>
            <option value="ARIA20">Arianee</option>
            <option value="BIR">Birake</option>
            <option value="VAL">Validity</option>
            <option value="MONA">Monavale</option>
            <option value="GO">GoChain</option>
            <option value="BAO">Bao Finance</option>
            <option value="ELONONE">ASTROELON</option>
            <option value="ELONONE">ASTROELON</option>
            <option value="NAV">Navcoin</option>
            <option value="GZIL">governance ZIL</option>
            <option value="SOC">All Sports</option>
            <option value="HEGIC">Hegic</option>
            <option value="CPH">Cypherium</option>
            <option value="TROY">Troy</option>
            <option value="TTT">The Transfer Token</option>
            <option value="DHT">dHedge DAO</option>
            <option value="SEELE">Seele</option>
            <option value="ONX">Onix</option>
            <option value="COT">CoTrader</option>
            <option value="LUNR">Lunr</option>
            <option value="XDB">DigitalBits</option>
            <option value="DMD">Diamond</option>
            <option value="DRGN">Dragonchain</option>
            <option value="XPX">ProximaX</option>
            <option value="RAE">RAE Token</option>
            <option value="BONDLY">Bondly V1 (OLD)</option>
            <option value="MHC">#MetaHash</option>
            <option value="SAN">Santiment Network Token</option>
            <option value="ASK">Permission Coin</option>
            <option value="ZCN">0chain</option>
            <option value="MTA">Meta</option>
            <option value="WTC">Waltoncoin</option>
            <option value="SNOW">SnowBlossom</option>
            <option value="KAN">BitKan</option>
            <option value="ZYN">Zynecoin</option>
            <option value="XHV">Haven Protocol</option>
            <option value="GMCOIN">GMCoin</option>
            <option value="ATRI">Atari</option>
            <option value="XEP">Electra Protocol</option>
            <option value="GRIN">Grin</option>
            <option value="UBEX">UBEX</option>
            <option value="VINU">Vita Inu</option>
            <option value="BTC2">Bitcoin 2</option>
            <option value="XED">Exeedme (ERC-20)</option>
            <option value="AMB">Ambrosus</option>
            <option value="WOM">WOM Token</option>
            <option value="STAKE">xDAI Stake</option>
            <option value="ZANO">Zano</option>
            <option value="YOP">Yield Optimization Platform (ERC-20)</option>
            <option value="NEC">Nectar</option>
            <option value="VIDYA">Vidya</option>
            <option value="ULT">Shardus</option>
            <option value="MONSTA">Cake Monster</option>
            <option value="CAS">Cashaa</option>
            <option value="777">Jackpot</option>
            <option value="FWB">Friends With Benefits</option>
            <option value="TCT">TokenClub</option>
            <option value="CARD">Cardstack</option>
            <option value="LA">LAToken</option>
            <option value="VEE">BLOCKv</option>
            <option value="SHA">Safe Haven</option>
            <option value="DACXI">Dacxi</option>
            <option value="PIB">Pibble</option>
            <option value="PAC">PAC Global</option>
            <option value="DSLA">DSLA Protocol</option>
            <option value="SIGNA">Signa</option>
            <option value="DUCK">Unit Protocol New</option>
            <option value="ORAI">Oraichain Token</option>
            <option value="AMDG">AMDG Token</option>
            <option value="BSK">BitcoinStaking</option>
            <option value="MPS">Mt Pelerin Shares</option>
            <option value="XPAT">Pangea Arbitration Token (Bitnation)</option>
            <option value="BLT">Bloom</option>
            <option value="DYP">DeFi Yield Protocol</option>
            <option value="GSWAP">Gameswap</option>
            <option value="SFI">Spice</option>
            <option value="TKP">TOKPIE</option>
            <option value="PICKLE">Pickle Finance</option>
            <option value="JRT">Jarvis Reward Token</option>
            <option value="PEPECASH">PepeCash</option>
            <option value="PALG">PalGold</option>
            <option value="NVT">Nerve Network</option>
            <option value="GRC">GridCoin</option>
            <option value="TRA">Trabzonspor Fan Token</option>
            <option value="1UP">1-UP</option>
            <option value="MOBI">Mobius</option>
            <option value="ZB">ZB</option>
            <option value="PI">PHCHAIN</option>
            <option value="JGN">Juggernaut</option>
            <option value="THT">Thought</option>
            <option value="MBX">MobieCoin</option>
            <option value="JACY">Jacy</option>
            <option value="HBD">Hive Dollar</option>
            <option value="EOSDT">EOSDT</option>
            <option value="XRT">Robonomics (ERC-20)</option>
            <option value="MIST">Alchemist</option>
            <option value="CHI">XAYA</option>
            <option value="RING">Darwinia Network Native Token</option>
            <option value="UNIDX">UniDex</option>
            <option value="PULSEDOGE">PulseDoge</option>
            <option value="DBDT">Digital BDT</option>
            <option value="CGT">CACHE Gold</option>
            <option value="SFD">SAFE DEAL</option>
            <option value="SIB">SIBCoin</option>
            <option value="NANJ">NANJCOIN</option>
            <option value="SHROOM">SHROOM Token (Niftyx)</option>
            <option value="ABYSS">Down Below</option>
            <option value="STRAT">Stratis</option>
            <option value="GEEQ">GEEQ</option>
            <option value="SHROOM">SHROOM Token (Niftyx)</option>
            <option value="UPI">Pawtocol</option>
            <option value="DTX">DaTa eXchange Token</option>
            <option value="SHI">Shina Inu</option>
            <option value="CRP">Crypton</option>
            <option value="BSCX">BSCEX</option>
            <option value="MTV">MultiVAC</option>
            <option value="MPH">88mph</option>
            <option value="DPS">DEEPSPACE</option>
            <option value="ZOO">ZooKeeper</option>
            <option value="MAHA">MahaDAO</option>
            <option value="PROB">Probit Token</option>
            <option value="PPT">Populous</option>
            <option value="SAFE">SafeCoin</option>
            <option value="VSYS">V Systems</option>
            <option value="PXP">PointPay PXP V2</option>
            <option value="AWX">AurusCOIN</option>
            <option value="VINU">Vita Inu (BEP-20)</option>
            <option value="SALT">SALT</option>
            <option value="MITX">Morpheus Labs</option>
            <option value="STON">Ston</option>
            <option value="XOR">Sora</option>
            <option value="SHX">Stronghold Token</option>
            <option value="FCT">Factom</option>
            <option value="FRM">Ferrum Network</option>
            <option value="GNY">GNY</option>
            <option value="SATT">Smart Advertising Transaction Token</option>
            <option value="XFT">Offshift</option>
            <option value="LYM">Lympo</option>
            <option value="BIS">Bismuth</option>
            <option value="SLINK">sLINK</option>
            <option value="SRK">SparkPoint</option>
            <option value="CPAY">Cryptopay</option>
            <option value="OTO">OTOCASH</option>
            <option value="OWC">Oduwa Coin</option>
            <option value="NXT">Nxt</option>
            <option value="SMI">SafeMoon Inu</option>
            <option value="TON">TONToken</option>
            <option value="SKEY">SmartKey</option>
            <option value="ETP">Metaverse ETP</option>
            <option value="SPANK">SpankChain</option>
            <option value="RDN">Raiden Network Token</option>
            <option value="GXT">Gem Exchange And Trading</option>
            <option value="BCDT">Blockchain Certified Data Token</option>
            <option value="SMT">SmartMesh</option>
            <option value="OAX">OAX</option>
            <option value="STRONG">Strong</option>
            <option value="CWBTC">cWBTC</option>
            <option value="PND">Pandacoin</option>
            <option value="SRCX">Source Token</option>
            <option value="WOW">Wownero</option>
            <option value="COV">Covesting</option>
            <option value="FREE">FREE Coin</option>
            <option value="SKY">Skycoin</option>
            <option value="CNHT">Tether CNH</option>
            <option value="DOUGH">PieDAO DOUGH v2</option>
            <option value="DDIM">DuckDaoDime</option>
            <option value="OOKS">Onooks</option>
            <option value="WABI">Tael</option>
            <option value="RBITX">rBitex</option>
            <option value="SNC">SunContract</option>
            <option value="ROBOT">Robot</option>
            <option value="LCC">Litecoin Cash</option>
            <option value="BOX">DeFiBox</option>
            <option value="SPC">SpaceChain (ERC-20)</option>
            <option value="FUEL">Fuel Token</option>
            <option value="LAMB">Lambda</option>
            <option value="CPD">Coinspaid</option>
            <option value="SCC">Stakecube</option>
            <option value="DIGG">DIGG</option>
            <option value="OLT">OneLedger</option>
            <option value="SWRV">Swerve</option>
            <option value="XCUR">Curate</option>
            <option value="ADK">Aidos Kuneen</option>
            <option value="HART">Hara Token</option>
            <option value="DBC">DeepBrain Wallet</option>
            <option value="BIX">Bibox Token</option>
            <option value="VIB">Viberate</option>
            <option value="RFUEL">Rio DeFi</option>
            <option value="UBXT">UpBots (ERC-20)</option>
            <option value="BUY">Burency</option>
            <option value="1337">1337</option>
            <option value="MM">Million</option>
            <option value="KEX">KIRA Network</option>
            <option value="DIO">Decimated</option>
            <option value="POLYDOGE">PolyDoge</option>
            <option value="PLOT">PlotX</option>
            <option value="GHOST">GHOST</option>
            <option value="MTH">Monetha</option>
            <option value="0XBTC">0xBitcoin</option>
            <option value="GTH">Gather</option>
            <option value="ERSDL">UnFederalReserve</option>
            <option value="TCC">The ChampCoin</option>
            <option value="SLT">Smartlands</option>
            <option value="WHACKD">Whackd</option>
            <option value="STQ">Storiqa</option>
            <option value="INT">Internet Node Token</option>
            <option value="CMERGE">CoinMerge (ERC-20)</option>
            <option value="LUA">Lua Token</option>
            <option value="BOLE">Bole</option>
            <option value="ABL">Airbloc</option>
            <option value="UWL">UniWhales.io</option>
            <option value="FIN">DeFiner</option>
            <option value="MOTA">MotaCoin</option>
            <option value="EMAX">EthereumMax 3</option>
            <option value="ZEE">ZeroSwap</option>
            <option value="KOM">Kommunitas</option>
            <option value="HODL">HODL</option>
            <option value="TIPS">FedoraCoin</option>
            <option value="BTCZ">BitcoinZ</option>
            <option value="AZR">Aezora</option>
            <option value="BAX">BABB</option>
            <option value="QBX">Qiibee Foundation</option>
            <option value="SNTVT">Sentivate</option>
            <option value="KRN">KRYZA Network</option>
            <option value="SUTER">Suterusu (ERC-20)</option>
            <option value="DC">DATACHAIN</option>
            <option value="CMT">CyberMiles</option>
            <option value="UNCL">UNCL</option>
            <option value="VYFI">VyFi</option>
            <option value="IQN">IQeon</option>
            <option value="SUPER">SuperCoin</option>
            <option value="CBC">CashBet</option>
            <option value="QLC">QLC Chain</option>
            <option value="CLU">CluCoin</option>
            <option value="UBQ">Ubiq</option>
            <option value="CUMMIES">CumRocket</option>
            <option value="EVC">Eco Value Coin</option>
            <option value="XCASH">X-Cash</option>
            <option value="RCN">Ripio Credit Network</option>
            <option value="DIME">Dimecoin</option>
            <option value="TRADE">UniTrade</option>
            <option value="TDX">Tidex Token</option>
            <option value="BFT">BnkToTheFuture</option>
            <option value="BHC">Billion Happiness</option>
            <option value="YAM">YAM</option>
            <option value="XPM">Primecoin</option>
            <option value="UNIFI">Unifi</option>
            <option value="BUIDL">DFOhub</option>
            <option value="1WO">1World</option>
            <option value="TXL">Tixl Token (ERC-20)</option>
            <option value="PAY">TenX</option>
            <option value="NAS">Nebulas</option>
            <option value="EFL">e-Gulden</option>
            <option value="SMARTCREDIT">SmartCredit Token</option>
            <option value="DOV">DOVU</option>
            <option value="SWINGBY">Swingby (BEP-2)</option>
            <option value="DEC">Decentr</option>
            <option value="GRLC">Garlicoin</option>
            <option value="ACS">ACryptoS</option>
            <option value="ZER">Zero</option>
            <option value="BCP">PieDAO Balanced Crypto Pie</option>
            <option value="VSF">VeriSafe</option>
            <option value="SWAG">SWAG Finance</option>
            <option value="SMART">SmartCash</option>
            <option value="ORS">Origin Sport Token</option>
            <option value="TERN">Ternio</option>
            <option value="SRN">SIRIN LABS Token</option>
            <option value="SLP">Smooth Love Potion</option>
            <option value="iDNA">Idena</option>
            <option value="EPS">Ellipsis (OLD)</option>
            <option value="VOICE">VOICE Token</option>
            <option value="PMGT">Perth Mint Gold Token</option>
            <option value="SIN">SINOVATE</option>
            <option value="SPH">Spheroid Universe</option>
            <option value="HYVE">Hyve</option>
            <option value="MER">Mercury</option>
            <option value="PBTC">pTokens BTC V1 (OLD)</option>
            <option value="HELP">GoHelpFund</option>
            <option value="AT">ABCC Token</option>
            <option value="BLOCK">Blocknet</option>
            <option value="XPC">eXPerience Chain</option>
            <option value="COVER">Cover Protocol</option>
            <option value="WGR">Wagerr</option>
            <option value="XTP">Tap</option>
            <option value="XAUR">Xaurum</option>
            <option value="NYAN">Nyancoin</option>
            <option value="LAYER">UniLayer</option>
            <option value="XOT">Okuru Token</option>
            <option value="ACT">Achain</option>
            <option value="CS">Credits</option>
            <option value="EYE">Behodler</option>
            <option value="VEX">Vexanium</option>
            <option value="MASS">MASS</option>
            <option value="BTX">Bitcore</option>
            <option value="TOP">Top Network</option>
            <option value="DUCK">DLP Duck Token</option>
            <option value="EGT">Egretia</option>
            <option value="DEPAY">DePay</option>
            <option value="SYNC">Sync Network</option>
            <option value="SPC">SpaceChain</option>
            <option value="AOA">Aurora</option>
            <option value="SPARTA">Spartan Protocol Token V2</option>
            <option value="BIRD">Bird.Money</option>
            <option value="RES">Resfinex Token</option>
            <option value="ENQ">Enecuum</option>
            <option value="STPL">Stream Protocol</option>
            <option value="NORD">Nord Finance (ERC-20)</option>
            <option value="SFIL">SFIL</option>
            <option value="DAX">DAEX</option>
            <option value="PLR">Pillar</option>
            <option value="IGG">IG Gold</option>
            <option value="OCTO">OctoFi</option>
            <option value="HNST">Honest</option>
            <option value="ASKO">Askobar Network</option>
            <option value="BRGX">Bridge$</option>
            <option value="VIA">Viacoin</option>
            <option value="MINDS">Minds</option>
            <option value="YOU">YOU COIN</option>
            <option value="MASQ">MASQ</option>
            <option value="CND">Cindicator</option>
            <option value="VALUE">Value Liquidity</option>
            <option value="DCN">Dentacoin</option>
            <option value="FNT">Falcon Token</option>
            <option value="FTC">Feathercoin</option>
            <option value="DTA">Data</option>
            <option value="SWFL">Swapfolio</option>
            <option value="CMERGE">CoinMerge (BEP-20)</option>
            <option value="LINKA">LINKA</option>
            <option value="RUFF">RuffChain</option>
            <option value="NEU">Neumark</option>
            <option value="GHOST">GHOSTPRISM</option>
            <option value="AUR">Auroracoin</option>
            <option value="FLOAT">Float Protocol: Float</option>
            <option value="AXPR">aXpire</option>
            <option value="BTB">BitBall</option>
            <option value="CLOAK">CloakCoin</option>
            <option value="VD">Bitcoin Card</option>
            <option value="CAT">BlockCat</option>
            <option value="DINU">Dogey-Inu</option>
            <option value="NLG">Gulden</option>
            <option value="REL">Relevant</option>
            <option value="TWINS">win.win</option>
            <option value="ABST">ABITSHADOW TOKEN</option>
            <option value="NPXS">Pundi X</option>
            <option value="OCE">OceanEX Token</option>
            <option value="OMNI">Omni</option>
            <option value="CUBIC">Cubics</option>
            <option value="ARCONA">ARCONA</option>
            <option value="ONION">DeepOnion</option>
            <option value="UFO">Uniform Fiscal Object</option>
            <option value="PULI">Puli</option>
            <option value="SENT">Sentinel</option>
            <option value="PP">PP</option>
            <option value="EFT">Energyfi</option>
            <option value="CCX">Conceal</option>
            <option value="GNX">Genaro Network</option>
            <option value="HGOLD">HollyGold</option>
            <option value="SASHIMI">Sashimi</option>
            <option value="MRX">Metrix Coin</option>
            <option value="PEPS">PEPS Coin</option>
            <option value="RENZEC">renZEC (ERC-20)</option>
            <option value="CV">cVToken</option>
            <option value="AME">Amepay</option>
            <option value="MAN">Matrix AI Network</option>
            <option value="MEGA">MegaCryptoPolis</option>
            <option value="ENG">Enigma</option>
            <option value="KAT">Kambria Token</option>
            <option value="AIT">AICHAIN</option>
            <option value="MDA">Moeda Loyalty Points</option>
            <option value="HTML">HTMLCOIN</option>
            <option value="DEC">Darico Ecosystem Coin</option>
            <option value="BRD">Bread</option>
            <option value="ROOBEE">Roobee Platform</option>
            <option value="EFX">Effect.AI</option>
            <option value="AMLT">AMLT</option>
            <option value="HINA">Hina Inu</option>
            <option value="YEC">Ycash</option>
            <option value="OCN">Odyssey</option>
            <option value="AOG">smARTOFGIVING</option>
            <option value="QRK">Quark</option>
            <option value="WISB">Wise Token</option>
            <option value="POT">PotCoin</option>
            <option value="EUNO">EUNO</option>
            <option value="TRCL">Treecle</option>
            <option value="FIX">FIX Network</option>
            <option value="BANK">Float Protocol: Bank</option>
            <option value="UNISTAKE">Unistake</option>
            <option value="TFLOW">TradeFlow</option>
            <option value="YAX">yAxis</option>
            <option value="ZAP">Zap Token</option>
            <option value="HOT">Hydro Protocol</option>
            <option value="BLK">BlackCoin</option>
            <option value="REDPANDA">Red Panda</option>
            <option value="XIO">XIO</option>
            <option value="ITC">IoT Chain</option>
            <option value="1FLR">Flare Token</option>
            <option value="PUT">PutinCoin</option>
            <option value="DGX">Digix Gold</option>
            <option value="RED">RedLang</option>
            <option value="LBA">Cred</option>
            <option value="HY">Hybrix</option>
            <option value="GYSR">Geyser</option>
            <option value="DEV">Dev Protocol</option>
            <option value="SG">SocialGood</option>
            <option value="GLEEC">Gleec Coin</option>
            <option value="DNA">EncrypGen</option>
            <option value="C20">CRYPTO20</option>
          </datalist>
          <button type="submit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              class="feather feather-search"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
          </button>
        </form>
      </div>

      {show ? (
        <div className="container coincontent">
          {coin.buy == null ? (
            <p className="notfound">Sorry We are not able to find this coin</p>
          ) : (
            <ul>
              <li>
                <img
                  className="icon"
                  src={`https://coinsapi.netlify.app/${query}.svg`}
                  alt=""
                />
                <h2 className="label">
                  {" "}
                  {coin.symbol}{" "}
                  <span
                    id={coin.changeRate < 0 ? "minus" : "plus"}
                    className="change"
                  >
                    {" "}
                    {coin.changeRate} %
                  </span>{" "}
                </h2>
                <h3>
                  {" "}
                  Currunt price - <span>$ {coin.buy}</span>{" "}
                </h3>
                <h3>
                  {" "}
                  24H High - <span>$ {coin.high}</span>{" "}
                </h3>
                <h3>
                  {" "}
                  24H Low - <span>$ {coin.low}</span>{" "}
                </h3>
              </li>
            </ul>
          )}
        </div>
      ) : null}
    </>
  );
};

export default Crypto;
